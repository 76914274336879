import Size from './size'

const Device = {
    mobileS: `(min-width: ${Size.mobileS})`,
    // mobileM: `(min-width: ${Size.mobileM})`,
    tablet: `(min-width: ${Size.tablet})`,
    laptop: `(min-width: ${Size.laptop})`,
    desktop: `(min-width: ${Size.desktop})`,
}

export default Device
