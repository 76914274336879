import styled from "styled-components"
import Link from 'gatsby-link'
import Device from "../responsive/device"

export const txtCol = "#333"
export const keyCol = "#1d2088"
export const subCol = "#f0f1fc"
export const linkCol = txtCol

export const LoadingWrapper = styled.div`
  position: relative;

  #loading {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: ${keyCol};
    left: 0px;
    top: 0px;
    z-index: 999;
    width: 100%;
    height: 100%;
  }

  #loadingLogo {
    position: fixed;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*display: none;*/
    z-index: 9999;
    opacity: 0;
  }

  @media ${Device.laptop} {
    #loadingLogo {
      width: 40%;
    }
  }
`

export const HdrLogo = styled.div`
  width: 70px;
`

export const MobileMenuLogo = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  div{
    position: absolute;
    width: 130px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const StyledLink = styled(Link)`
  color: ${txtCol}
`

export const IndxMain = styled.main`
  display: flex;
  flex-flow: column wrap;
  gap: 60px;

  @media ${Device.tablet} {
    flex: 1;
    gap: 100px;
  }

  @media ${Device.laptop} {
    gap: 180px;
  }
`

export const ElseMain = styled(IndxMain)`
  @media ${Device.laptop} {
    flex-flow: row wrap;
    gap: 0 60px;
  }

  @media ${Device.desktop} {
    gap: 0 100px;
  }
`
export const MainChild = styled.div`
  @media ${Device.laptop} {
    flex: 1;
  }
`

export const Aside = styled.aside`
  display: flex;
  flex-flow: column wrap;
  gap: 60px;

  @media ${Device.tablet} {
    flex-flow: row wrap;
    gap: 40px;
    padding-bottom: 0;

    > div {
      width: calc(50% - 20px);
    }
  }

  @media ${Device.laptop} {
    flex-flow: column wrap;
    gap: 60px;
    width: 200px;
    padding-top: 117px;

    > div {
      width: 100%;
    }
  }

  @media ${Device.desktop} {
    flex-flow: column wrap;
    gap: 80px;
    width: 250px;

    > div {

    }
  }
`

export const Container = styled.div`
  padding: 120px 7.5% 0;

  @media ${Device.tablet} {
    display: flex;
    flex-flow: row wrap;
    gap: 0 40px;
    padding: 50px 40px 0;
    border-top: 10px solid ${keyCol};
  }

  @media ${Device.laptop} {
    flex-wrap: nowrap;
  }

  @media ${Device.desktop} {
    padding: 50px 70px 0 60px;
    gap: 0 80px;
  }
`

export const H1 = styled.h1`
  font-size: 2.4rem;
  letter-spacing: .15em;
  margin-bottom: 50px;
  color: ${keyCol};
`

export const H2 = styled.h2`
  font-size: 1.8rem;
  letter-spacing: .15em;
  margin-bottom: 20px;
  color: ${keyCol};
`

export const DefinitionList = styled.dl`
  display: flex;
  flex-wrap: wrap;

  dt {
    width: 80px;
    font-size: 1.4rem;
    color: ${keyCol};
    margin-right: 10px;
    margin-bottom: 5px;
    line-height: 2;
  }

  dd {
    width: calc(100% - 90px);
    font-size: 1.4rem;
    margin-bottom: 5px;
    line-height: 2;
  }

  + ${H2} {
    margin-top: 50px;
  }
`

export const Paragraph = styled.p`
  font-size: 1.4rem;
  margin-bottom: 15px;
  line-height: 2;

  + ${H2} {
    margin-top: 50px;
  }
`

export const BookWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 30px;
  margin-bottom: 50px;

  @media ${Device.tablet} {
    flex-flow: row wrap;
    gap: 0 40px;
    margin-bottom: 100px;
  }

  @media ${Device.laptop} {
  }

  @media ${Device.desktop} {
    gap: 0 70px;
  }
`

export const BookThumbTtl = styled.h3`
  color: ${txtCol};
  font-size: 1.6rem;
  margin-bottom: 10px;
  letter-spacing: .15em;
  font-weight: 400;
  transition: .2s;
  }
`
export const BookThumbMeta = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`

export const BookThumbAut = styled.p`
  color: ${txtCol};
  font-size: 1.4rem;
  letter-spacing: .15em;
  position: relative;
  padding-right: 20px;
  margin-right: 20px;

  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 16px;
    position: absolute;
    bottom: 2px;
    right: 0;
    background-color: ${keyCol};
  }

  @media ${Device.tablet} {
    padding: 0;
    margin: 0;
  }
`

export const BookThumbCat = styled.p`
  font-size: 1.1rem;
  letter-spacing: .15em;
  color: ${keyCol};
`

export const LeftWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${Device.tablet} {
    width: 200px;
  }
`

export const SlideWrapper = styled.div`
  max-width: 85vw;
  width: 100%;
  cursor: zoom-in;
  /*overflow: hidden;*/

  .gatsby-image-wrapper {
    max-height: 380px;

    img {
      width:auto;
      height:auto;
      max-width:100%;
      max-height:100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media ${Device.tablet} {
    /*margin-bottom: 70px;*/

    .gatsby-image-wrapper {
      max-height: initial;

      img {
        left: 0;
        transform: none;
      }
    }
  }

`

export const RightWrapper = styled.div`
  flex: 1;
`

export const BookPostHeader = styled.div`
  margin-bottom: 40px;

  @media ${Device.tablet} {
    margin-bottom: 80px;
  }
`

export const BookPostTtl = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${keyCol};

  h1 {
    font-size: 2.0rem;
    color: ${keyCol};
    letter-spacing: 0.15em;
    line-height: 1.4;
    font-weight: 700;
  }

  @media ${Device.tablet} {
    padding-bottom: 30px;

    h1 {
      font-size: 2.4rem;
    }
  }
`

export const BookPostAut = styled.div`
  font-size: 1.4rem;
  color: ${keyCol};
  margin-bottom: 10px;
  letter-spacing: 0.15em;

  @media ${Device.tablet} {
    margin-bottom: 15px;
  }
`

export const BookPostMeta = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  dt {
    width: 80px;
    font-size: 1.2rem;
    color: ${keyCol};
    margin-bottom: 5px;
  }

  dd {
    width: calc(100% - 80px);
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`

export const BookPostImg = styled.div`

`

export const ShopBtnStyle = styled.div`
`

export const ShopBtnWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  margin-top: 50px;

  a {
    display: flex;
    font-size: 1.1rem;
    height: 46px;
    width: calc(50% - 4px);
    border: 1px solid ${keyCol};
    color: ${keyCol};
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1em;
    transition: .2s;

    &:hover {
      background-color: ${subCol};
    }
  }

  .icon-amazon,
  .icon-rakuten {
    width: 80px;
  }

  .icon-hanmoto {
    width: 100px;
  }

  @media ${Device.tablet} {
    flex-flow: column wrap;
    gap: 15px;

    a {
      font-size: 1.4rem;
      width: 100%;
    }
  }
`

export const WidgetWrapper = styled.div`
`

export const WidgetTitle = styled.div`
  font-size: 1.2rem;
  letter-spacing: 0.2em;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: ${keyCol};
  font-weight: 700;

  &::after {
    content: "";
    flex-grow: 1;
    height: 3px;
    margin-left: 10px;
    display: block;
    background-color: ${keyCol};
  }

  @media ${Device.tablet} {
    margin-bottom: 30px;
  }
`

export const BookThumb = styled.article`
  width: 100%;
  position: relative;
`

export const BookThumbTxt = styled.div`
  flex: 1;
`

export const BookThumbImg = styled.div`
  display: flex;
  height: 270px;
  background-color: ${subCol};
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

export const BadgeSoon = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: 1px solid ${keyCol};
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;

  span {
    text-align: center;
    font-size: .8rem;
    color: ${keyCol};
    line-height: 1.2;
    letter-spacing: 0.1em;
  }

  @media ${Device.tablet} {
    top: -20px;
    left: -20px;
  }
`

export const BadgeNew = styled(BadgeSoon)`
  background-color: ${keyCol};

  span {
    color: #fff;
    line-height: 1.2;
  }
`

export const WidgetBooksBody = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 20px;

  ${BookThumb} {
    width: 100%;
  }

  ${BookThumbImg} {
    display: inline-block;
    width: 70px;
    height: auto;
    margin-right: 20px;
    background-color: transparent;
    margin-bottom: 0;
  }

  ${BookThumbTtl} {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  ${BookThumbAut} {
    font-size: 1.2rem;

    &::after {
      height: 14px;
      bottom: 1px;
    }
  }

  ${BookThumbCat} {
    font-size: 1.1rem;
  }

  ${BadgeSoon} {
    display: none;
  }

  ${BadgeNew} {
    display: none;
  }

  a {
    display: flex;
    transition: .2s;

    &:hover {
      ${BookThumbTtl} {
        font-weight: 700;
      }
    }
  }

  @media ${Device.tablet} {
    ${BookThumb} {
      gap: 25px;
    }

    ${BookThumbMeta} {
      flex-flow: column wrap;
      align-items: flex-start;
    }

    ${BookThumbAut} {
      margin-bottom: 18px;

      &::after {
        width: 10px;
        height: 1px;
        left: 0;
        bottom: -10px;
      }
    }
  }
`

export const NewsThumbImg = styled.div`
  position: relative;
  /*display: flex;
  aspect-ratio: 8 / 5;
  justify-content: center;
  align-items: center;*/
  width: 100%;
  margin-bottom: 20px;

  &::before {
    content: "";
    display: block;
    padding-top: 62.5%;
  }
`

export const NewsThumbTtl = styled.div`
  font-size: 1.6rem;
  margin-bottom: 10px;
  color: ${txtCol};
  transition: .2s;
`

export const NewsDate = styled.time`
  font-size: 1.2rem;
  letter-spacing: .15em;
  color: ${keyCol};
  padding-right: 20px;
  margin-right: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 14px;
    right: 0;
    bottom: 1px;
    background-color: ${keyCol};
  }
`

export const NewsThumbDate = styled(NewsDate)`
  width: 100px;
`

export const NewsThumbCat = styled.span`
  font-size: 1.1rem;
  letter-spacing: .15em;
  color: ${keyCol};
`

export const IndxNews = styled.section`
  padding: 40px 30px;
  border: 3px solid ${keyCol};

  @media ${Device.tablet} {
    padding: 50px 60px;
    margin-bottom: 0;
  }

  @media ${Device.laptop} {
    padding: 60px 70px;
  }

  @media ${Device.desktop} {
    padding: 60px 100px;
    margin-right: 50px;
  }
`

export const NewsThumb = styled.article`
  width: 100%;
`

export const IndxNewsListWrapper = styled.div`

`

export const IndxBooks = styled.section`

  @media ${Device.tablet} {
    /* 768 - 1023 */
    padding-top: 50px;
  }

  @media ${Device.laptop} {
    /* 1024 - 1439 */
  }

  @media ${Device.desktop} {
    /* 1440 - 1920 */
    padding-top: 100px;
    padding-right: 50px;
  }
`

export const IndxBooksListWrapper = styled.div`
`

export const IndxBooksHdr = styled.div`
  h2 {
    font-size: 1.8rem;
    margin-bottom: 50px;
    letter-spacing: 0.15em;
    color: ${keyCol};
    text-align: center;
  }

  @media ${Device.tablet} {
    h2 {
      text-align: left;
    }
  }
`

export const IndxBooksBdy = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-flow: column wrap;
  gap: 50px;

  a {
    .gatsby-image-wrapper {
      width: 150px;
      transition: .3s;
    }

    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.05);
      }

      ${BookThumbTtl} {
        font-weight: 700;
      }
    }
  }

  @media ${Device.tablet} {
    flex-flow: row wrap;
    gap: 50px 40px;
    margin-bottom: 80px;

    ${BookThumb} {
      width: calc(50% - 20px);
    }

    ${BookThumbMeta} {
      flex-flow: column wrap;
      align-items: flex-start;
    }

    ${BookThumbAut} {
      margin-bottom: 25px;

      &::after {
        width: 20px;
        height: 1px;
        bottom: -12px;
        left: 0;
      }
    }
  }

  @media ${Device.laptop} {
    gap: 50px 39px;

    ${BookThumb} {
      width: calc(33.33333% - 26px);
    }

    ${BookThumbImg} {
      height: 300px;
    }
  }

  @media ${Device.desktop} {
    /* 1440 - 1920 */
    gap: 50px 40px;

    ${BookThumb} {
      width: calc(25% - 30px);
    }
  }

`

export const BookListWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 50px;

  a {
    .gatsby-image-wrapper {
      width: 135px;
      transition: .3s;
    }

    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.05);
      }

      ${BookThumbTtl} {
        font-weight: 700;
      }
    }
  }

  @media ${Device.tablet} {
    flex-flow: row wrap;
    gap: 50px 40px;

    ${BookThumbMeta} {
      flex-flow: column wrap;
      align-items: flex-start;
    }

    ${BookThumb} {
      width: calc(50% - 20px);
    }

    ${BookThumbAut} {
      margin-bottom: 25px;

      &::after {
        width: 20px;
        height: 1px;
        bottom: -12px;
        left: 0;
      }
    }
  }

  @media ${Device.laptop} {
    gap: 50px 40px;

    ${BookThumb} {
      width: calc(50% - 20px);
    }
  }

  @media ${Device.desktop} {
    gap: 50px 39px;

    ${BookThumb} {
      width: calc(33.33333% - 26px);
    }
  }
`

export const IndxNewsHdr = styled.div`
  margin-bottom: 30px;

  h2 {
    font-size: 1.8rem;
    letter-spacing: .15em;
    color: ${keyCol};
    text-align: center;
  }

  @media ${Device.tablet} {
    h2 {
      text-align: left;
    }
  }
`

export const IndxNewsBdy = styled.div`
  margin-bottom: 40px;

  ${NewsThumb} {
    width: 100%;

    a {
      display: block;
      padding: 15px 0;
      border-top: 1px solid ${keyCol};

      :hover {
        ${NewsThumbTtl} {
          font-weight: 700;
        }
      }
    }

    &:last-of-type {
      border-bottom: 1px solid ${keyCol};
    }
  }

  ${NewsThumbTtl} {
    margin-bottom: 5px;
  }

  ${NewsDate} {
    padding-right: 15px;
    margin-right: 15px;
  }

  ${NewsThumbImg} {
    display: none;
  }

  @media ${Device.tablet} {

  }

  @media ${Device.laptop} {
    margin-bottom: 50px;

    ${NewsThumb} {
      a {
        display: flex;
        padding: 25px 0;
        flex-flow: row wrap;
        align-items: center;
      }
    }

    ${NewsThumbTtl} {
      order: 3;
      flex: 1;
      margin-bottom: 0;
    }

    ${NewsThumbDate} {
    }

    ${NewsThumbCat} {
      order: 2;
      width: 90px;
      display: inline-block;
      text-align: center;
      margin-right: 20px;
    }

    ${NewsDate} {
      order: 1;
      width: 100px;
      margin: 0;
      padding: 0;

      &::after {
        content: none;
      }
    }
  }
`

export const IndxNewsFtr = styled.div`

`

export const NewsListWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 50px;

  a {
    &:hover {
      ${NewsThumbTtl} {
        font-weight: 700;
      }
    }
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /*width:auto;
    height:auto;
    max-width:100%;
    max-height:100%;*/

    img {
      object-fit: contain !important;
    }
  }

  @media ${Device.tablet} {
    flex-flow: row wrap;
    gap: 40px;

    ${NewsThumb} {
      width: calc(50% - 20px);
    }
  }

  @media ${Device.laptop} {
    gap: 60px 40px;
  }

  @media ${Device.desktop} {
    gap: 80px 100px;

    ${NewsThumb} {
      width: calc(50% - 50px);
    }
  }
`

export const WidgetNewsBody = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 20px;

  ${NewsThumb} {
    width: 100%;
  }

  ${NewsThumbTtl} {
    display: block;
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  ${NewsDate} {
    padding-right: 15px;
    margin-right: 15px;
  }

  ${NewsThumbImg} {
    display: none;
  }

  a {
    transition: .2s;

    &:hover {
      ${NewsThumbTtl} {
        font-weight: 700;
      }
    }
  }

  @media ${Device.laptop} {
    gap: 25px;
  }
`

export const WidgetCatBody = styled.div`
  ul {
    padding: 0;
    margin: 0;
  }

  li {
    font-size: 1.4rem;
    line-height: 2;
  }

  a {
    color: ${txtCol};
    display: block;
    transition: .2s;

    &:hover {
      font-weight: 700;
    }
  }
`

export const NewsWrapper = styled.div`
  margin: 0 auto 50px;

  @media ${Device.tablet} {
    margin: 100px auto;
  }



  @media ${Device.desktop} {
    width: 590px;
  }
`

export const NewsPostHeader = styled.div`
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${keyCol};

  @media ${Device.tablet} {
    padding-bottom: 40px;
    margin-bottom: 50px;
  }
`

export const NewsPostThumbImg = styled.div`
  /*display: flex;
  aspect-ratio: 8 / 5;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;*/
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  &::before {
    content: "";
    display: block;
    padding-top: 62.5%;
  }

  .gatsby-image-wrapper {
    /*width:auto;
    height:auto;
    max-width:100%;
    max-height:100%;*/
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    img {
      object-fit: contain !important;
    }
  }

  @media ${Device.tablet} {
      margin-bottom: 40px;
  }

  @media ${Device.laptop} {
      margin-bottom: 60px;
  }
`

export const NewsPostTtl = styled.h1`
  font-size: 2.0rem;
  margin-bottom: 10px;
  letter-spacing: .15em;
  color: ${keyCol};
  font-weight: 700;
  line-height: 1.4;

  @media ${Device.tablet} {
    margin-bottom: 25px;
    font-size: 2.4rem;
  }
`

export const NewsPostSub = styled.div`
  display: flex;
  align-items: center;
`

export const NewsPostMeta = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const NewsPostCat = styled.span`
  font-size: 1.1rem;
  letter-spacing: .15em;
  color: ${keyCol};
`

export const PageWrapper = styled.div`
  p a,
  dd a {
    text-decoration: underline;
  }
`

export const ThanksWrapper = styled.div`
  padding-bottom: 50px;

  ${H1} {
    text-align: center
  }

  ${Paragraph} {
    text-align: center;
    margin-bottom: 50px;
  }

  @media ${Device.tablet} {
    padding-top: 120px;
  }

`

export const RelatedNewsTtl = styled.h5`
  font-size: 1.6rem;
  color: ${txtCol};
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
  transition: .2s;

  @media ${Device.tablet} {
    margin-bottom: 20px;
  }
`

export const WidgetNewsRelated = styled.div`
  margin-bottom: 50px;

  @media ${Device.tablet} {
    margin-bottom: 100px;
  }
`

export const RelatedNewsBody = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 20px;

  article {
    border: 3px solid ${keyCol};
    padding: 20px;
  }

  a {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    gap: 15px;

    &:hover {
      ${RelatedNewsTtl} {
        font-weight: 700;
      }
    }
  }

  @media ${Device.tablet} {
    gap: 40px;

    article {
      padding: 30px 40px;
    }

    a {
      flex-flow: row wrap;
      gap: 30px;
    }
  }
`

export const RelatedNewsImg = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  &::before {
    content: "";
    display: block;
    padding-top: 62.5%;
  }

  @media ${Device.tablet} {
    width: 180px;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    img {
      object-fit: contain !important;
    }
  }
`

export const RelatedNewsTxt = styled.div`
  @media ${Device.tablet} {
    flex: 1;
  }
`

export const RelatedNewsMeta = styled.div`
  display: flex;
  align-items: center;
`

export const RelatedNewsCat = styled.span`
  font-size: 1.1rem;
  letter-spacing: .15em;
`

export const RelatedBooksWrapper = styled.div`
  margin-bottom: 50px;

  @media ${Device.tablet} {
    margin-bottom: 100px;
  }
`

export const RelatedTitle = styled.h4`
  font-size: 1.2rem;
  letter-spacing: 0.2em;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: ${keyCol};
  font-weight: 700;

  &::after {
    content: "";
    flex-grow: 1;
    height: 3px;
    margin-left: 10px;
    display: block;
    background-color: ${keyCol};
  }

  @media ${Device.tablet} {
    margin-bottom: 30px;
  }
`
export const RelatedBookTtl = styled.h5`
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 5px;
  letter-spacing: .15em;
  color: ${txtCol};
  transition: .2s;
`

export const RelatedBooksBody = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 20px;

  article {
    width: 100%;
  }

  a {
    display: flex;

    &:hover {
      ${RelatedBookTtl} {
        font-weight: 700;
      }
    }
  }

  @media ${Device.tablet} {
    flex-flow: row wrap;
    gap: 40px 50px;

    article {
      width: calc(50% - 25px);
    }
  }

  @media ${Device.laptop} {

  }

  @media ${Device.desktop} {
    gap: 50px 100px;

    article {
      width: calc(50% - 50px);
    }
  }
`

export const RelatedBookImg = styled.div`
  width: 70px;
  margin-right: 20px;
`

export const RelatedBookTxt = styled.div`
`

export const RelatedBookAut = styled.p`
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 1.2rem;
  color: ${txtCol};
  letter-spacing: .15em;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    right: 0;
    bottom: 1px;
    position: absolute;
    background-color: ${keyCol};
  }

  @media ${Device.tablet} {
    display: block;
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 18px;

    &::after {
      width: 10px;
      height: 1px;
      left: 0;
      bottom: -10px;
    }
  }
`

export const RelatedBookCat = styled.p`
  display: inline-block;
  font-size: 1.1rem;
  letter-spacing: .15em;

  @media ${Device.tablet} {
    display: block;
  }
`

export const VideoWrapper = styled.div`
  margin-bottom: 50px;

  &:last-of-type {
    margin-bottom: 150px;
  }
`

export const VideoCaption = styled.p`
  font-size: 1.2rem;
  margin-top: 10px;

  @media ${Device.tablet} {
    margin-top: 15px;
  }
`

export const Button = styled.a`
  display: inline-flex;
  width: 250px;
  height: 46px;
  font-size: 1.4rem;
  letter-spacing: 0.15em;
  border: 1px solid ${keyCol};
  border-radius: 23px;
  color: ${keyCol};
  background-color: #fff;
  transition: .2s;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${subCol};
  }
`

export const BtnWrapper = styled.div`
  text-align: center;

  a {
    display: inline-flex;
    width: 100%;
    font-size: 1.4rem;
    letter-spacing: 0.15em;
    height: 46px;
    text-align: center;
    border: 1px solid ${keyCol};
    border-radius: 23px;
    color: ${keyCol};
    transition: .2s;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${subCol};
    }
  }

  @media ${Device.tablet} {
    a {
      width: 250px;
    }
  }
`

export const IndxOrder = styled.section`
  display: flex;
  flex-flow: column wrap;

  @media ${Device.tablet} {
    width: 100%;
    flex-flow: row wrap;
    margin-bottom: 0;
    gap: 0px 20px;
  }

  @media ${Device.laptop} {
    gap: 0px 40px;
  }

  @media ${Device.desktop}{
    padding-right: 50px;
    gap: 0px 100px;
  }
`

export const IndxOrderGroup = styled.div`
  width: 100%;

  h2 {
    font-size: 1.8rem;
    letter-spacing: .15em;
    margin-bottom: 20px;
    color: ${keyCol};
    text-align: center;
  }

  &:first-of-type {
    margin-bottom: 40px;
  }

  @media ${Device.tablet} {
    width: calc(50% - 10px);

    h2 {
      margin-bottom: 30px;
    }

    &:first-of-type {
      margin-bottom: 0;
    }
  }

  @media ${Device.laptop} {
    width: calc(50% - 20px);
  }

  @media ${Device.desktop} {
    width: calc(50% - 50px);
  }
`

export const IndxOrderLg = styled.div`
  a {
    border: 3px solid ${keyCol};
    font-size: 1.8rem;
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
    transition: .2s;

    &:hover {
      background-color: ${subCol};
    }
  }

  @media ${Device.tablet} {
    a {
      height: 180px;
    }
  }

  @media ${Device.laptop} {
    a {
      height: 190px;
    }
  }
`

export const IndxOrderSm = styled.div`
  a {
    border: 3px solid ${keyCol};
    font-size: 1.8rem;
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
    transition: .2s;

    &:hover {
      background-color: ${subCol};
    }
  }

  &:first-of-type {
    margin-bottom: 10px;
  }

  &:nth-of-type(2) {
    margin-bottom: 10px
  }

  .icon-1satsu {
    width: 300px;
  }

  .icon-bookcellar {
    width: 230px;
  }

  @media ${Device.tablet} {
    &:first-of-type {
      margin-bottom: 20px;
    }

    &:nth-of-type(2) {
      margin-bottom: 20px
    }
  }
`

export const IndxSNS = styled.section`
  display: flex;
  flex-flow: column wrap;
  gap: 100px;

  @media ${Device.tablet} {
    flex-flow: row wrap;
    gap: 0 40px;
  }

  @media ${Device.laptop} {
  }

  @media ${Device.desktop} {
    gap: 0 150px;
    padding-right: 50px;
  }
`

export const IndxTwitter = styled.div`
  @media ${Device.tablet} {
    width: 230px;
  }

  @media ${Device.laptop} {
    width: 265px;
  }
  @media ${Device.desktop} {
    width: 300px;
  }

`

export const IndxNote = styled.div`

  @media ${Device.tablet} {
    flex: 1;
  }

  @media ${Device.laptop} {
  }
`

export const IndxNoteBdy = styled.div`
  margin-bottom: 40px;

  @media ${Device.tablet} {
    margin-bottom: 50px;
  }
`

export const IndxNoteTtl = styled.h2`
  margin-bottom: 25px;

  img {
    width: 100px;
  }
`

export const NotePostTtl = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${txtCol};
  transition: .2s;
`

export const NotePost = styled.article`
  padding: 15px 0;
  border-bottom: 1px solid ${keyCol};

  &:first-of-type {
    border-top: 1px solid ${keyCol};
  }

  a {
    &:hover {
      ${NotePostTtl} {
        font-weight: 700;
      }
    }
  }

  @media ${Device.tablet} {
    padding: 20px 0;
  }
`

export const NotePostDate = styled(NewsDate)`
  display: block;
  padding: 0;
  margin-right: 0;
  margin-bottom: 5px;

  &::after {
    content: none;
  }

  @media ${Device.tablet} {
    margin-bottom: 10px;
  }
`

export const StyledFooter = styled.footer`
`

export const FooterBottom = styled.div`
  background-color: ${keyCol};
  padding: 20px 0;
  text-align: center;

  small {
    font-size: 1.1rem;
    color: #fff;
    letter-spacing: 0.15em;
  }
`

export const FooterWrapper = styled.div`
  display: flex;
  padding: 30px 0;
  margin: 60px 7.5% 0;
  flex-flow: column wrap;
  border-top: 1px solid ${keyCol};

  @media ${Device.tablet} {
    padding: 50px 0;
    margin: 100px 40px 0;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @media ${Device.laptop} {
    padding: 50px 60px;
    margin: 150px 60px 0;
  }
`

export const FooterLogo = styled.p`
    width: 250px;
    margin-bottom: 20px;

    @media ${Device.tablet} {
      margin-bottom: 50px;
    }
`

export const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 2;

  @media ${Device.tablet} {
    align-items: flex-start;
    justify-content: space-between;
    order: 1;
  }

  @media ${Device.laptop} {

  }
`

export const BtnSNS = styled.div`
  a {
    transition: .2s;
    display: block;
  }

  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
      width:auto;
      height:auto;
      max-width:20px;
      max-height:20px;
    }
  }
`

export const StyledSNSGroup = styled.div`
  display: flex;
`

export const StyledNav = styled.nav`
  ul {
    margin-bottom: 25px;

    li {
      font-size: 1.4rem;
      letter-spacing: 0.15em;
      margin-bottom: 15px;
      color: ${keyCol};
      transition: .2s;

      &:hover {
        transform: translateX(5px);
        font-weight: 700;
      }
    }
  }
`

export const StyledMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: ${keyCol};
  top: 0;
  left: 0;
  z-index: 10;

  ${StyledSNSGroup} {
    margin-top: 10px;
  }

  ${BtnSNS} {
    a {
      border: 1px solid ${keyCol};
      border-radius: 20px;

      &:hover {
        background-color: ${subCol};
      }
    }

    span {
      width: 40px;
      height: 40px;
    }

    &:first-of-type {
      margin-right: 10px;
    }
  }

  @media ${Device.tablet} {
    display: none;
  }
`

export const FooterRight = styled.div`
  order: 1;
  margin-bottom: 40px;

  ${StyledNav} {
    ul {
      padding: 0;
      margin: 0;

      li {
        font-size: 1.4rem;
        margin-bottom: 5px;
        color: ${keyCol};
        letter-spacing: 0.15em;

        a {
          display: block;
        }
      }
    }
  }

  ${StyledSNSGroup} {
    display: block;
  }

  ${BtnSNS} {
    margin-bottom: 5px;

    a {
      display: flex;
      font-size: 1.4rem;
      letter-spacing: 0.15em;
      align-items: center;
    }

    span {
      width: 20px;
      height: 20px;
      color: ${keyCol};
      margin-right: 8px;
    }
  }

  @media ${Device.tablet} {
    order: 2;
    display: flex;
    margin-bottom: 0;

    ${StyledNav} {
      width: 200px;

      ul li {
        margin-bottom: 12px;
      }
    }

    ${BtnSNS} {
      margin-bottom: 12px;
    }
  }
`

/*export const FooterSNS = styled.ul`
  display: inline-block;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 8px;
  }

  a {
    font-size: 1.4rem;
    margin-bottom: 8px;
    color: ${keyCol};
    letter-spacing: .15em;
  }
`*/

export const FooterProfile = styled.dl`
  display: flex;
  flex-wrap: wrap;

  dt, dd {
    font-size: 1.1rem;
    letter-spacing: .15em;
    line-height: 2;
  }

  dt {
    width: 50px;
  }

  dd {
    width: calc(100% - 50px);
  }

  @media ${Device.tablet} {
    width: 310px;
  }
`

export const StyledHeader = styled.header`
  display: none;

  @media ${Device.tablet} {
    display: flex;
    position: sticky;
    width: 120px;
    height: calc(100vh - 60px);
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 60px;
    top: 60px;
    align-self: flex-start;
  }

  @media ${Device.laptop} {
    width: 150px;
  }

  @media ${Device.desktop} {
    width: 190px;
  }
`

export const HdrNav = styled.div`
  ${BtnSNS} {
    a {
      border: 1px solid ${keyCol};
      border-radius: 20px;

      &:hover {
        background-color: ${subCol};
      }
    }

    span {
      width: 40px;
      height: 40px;
    }

    &:first-of-type {
      margin-right: 10px;
    }
  }
`

export const BtnTwitterShare = styled.div`
  a {
    display: inline-flex;
    font-size: 1.0rem;
    height: 20px;
    width: 88px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${keyCol};
    border-radius: 10px;
    transition: .2s;

    &:hover {
      background-color: ${subCol};
    }
  }

  span {
    width: 13px;
    height: auto;
    margin-right: 3px;
  }
`

export const BtnNote = styled.div`
  a {
    display: flex;
    font-size: 1.6rem;
    color: ${keyCol};
    height: 120px;
    align-items: center;
    justify-content: center;
    border: 3px solid ${keyCol};
    transition: .2s;

    &:hover {
      background-color: ${subCol};
    }
  }

  img {
    width: 100px;
  }
`
export const BtnCircleGroup = styled.div`
  display: flex !important;
  flex-flow: row wrap;
  gap: 10px;
  padding-top: 20px !important;
`

export const BtnCircle = styled.button`
  display: inline-flex !important;
  padding: 1px 6px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 1px solid ${keyCol};
  border-radius: 20px;
  align-items: center;
  justify-content: center;

  img {
    width:auto;
    height:auto;
    max-width:20px;
    max-height:20px;
  }
`

export const Btn1satsu = styled.div`
  margin-top: 50px;

  a {
    display: flex;
    flex-flow: column wrap;
    gap: 5px;
    color: ${keyCol};
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    border: 3px solid ${keyCol};
    transition: .2s;

    &:hover {
      background-color: ${subCol};
    }
  }

  span {
    display: block;
    font-size: 1.6rem;
  }

  img {
    width: 230px;
  }

  @media ${Device.tablet} {
    a {
      width: 450px;
      height: 120px;
      margin: 0 auto;
      gap: 15px;
    }
  }
`

export const BtnBookCellar = styled.div`
  margin-top: 20px;

  a {
    display: flex;
    flex-flow: column wrap;
    gap: 5px;
    color: ${keyCol};
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    border: 3px solid ${keyCol};
    transition: .2s;

    &:hover {
      background-color: ${subCol};
    }
  }

  span {
    display: block;
    font-size: 1.6rem;
  }

  img {
    width: 230px;
  }

  @media ${Device.tablet} {
    a {
      width: 450px;
      height: 120px;
      margin: 0 auto;
      gap: 15px;
    }
  }
`

export const StyledGoogleMap = styled.div`
  margin-top: 50px;
`

export const LabelEbooks = styled.span`
  display: inline-block;
  font-size: 1.1rem;
  font-weight: normal;
  letter-spacing: .15em;
  color: #fff;
  background-color: ${keyCol};
  padding: 4px 10px;
  margin-top: 10px;
  border-radius: 12px;
`

export const StyledScroll = styled.div`
  display: none;

  @media ${Device.tablet} {
    display: block;

    #scrollBtn {
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 105px;
      right: 60px;
      z-index: 99;
      border: none;
      outline: none;
      background-color: ${keyCol};
      color: white;
      cursor: pointer;
      padding: 15px;
      border-radius: 30px;
      animation: fadeIn 1.5s ease 0s 1 normal;

      &hover {
        background-color: #555;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
