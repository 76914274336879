import React from "react"
import { Timeline } from 'react-twitter-widgets'
import Header from "./header"
import Footer from "./footer"
import Menu from "./mobileMenu"
import WidgetBooks from "./widgetBooks"
import WidgetNews from "./widgetNews"
import WidgetBooksCat from "./widgetBooksCat"
import WidgetNewsCat from "./widgetNewsCat"
import Scroll from './scroll'
// import Script from "../utils/script"
import { IndxMain, ElseMain, MainChild, Aside, Container, BtnNote } from "../style/common/common"
// import note from '../../icon-note-lg.svg'

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const newsPath = `${rootPath}news/`
  const isRootPath = location.pathname === rootPath
  const isNewsPath = location.pathname === newsPath
  const hasNewsPath = location.pathname.includes('/news')
  // const loading = (
  //   <LoadingWrapper>
  //     <div id="loading">
  //       <div id="loadingLogo">
  //         <img src={logoW} />
  //       </div>
  //     </div>
  //   </LoadingWrapper>
  // )

  let container

  if (isRootPath) {
    container = (
      <>
        <Header />
        <IndxMain>
          {children}
        </IndxMain>
      </>
    )
  } else if (hasNewsPath) {
    container = (
      <>
        <Header />
        <ElseMain>
          <MainChild>
            {children}
          </MainChild>
          <Aside>
            <WidgetNewsCat />
            <WidgetBooks />
            <BtnNote>
              <a href="https://note.com/soryusha/" target="_blank" rel="noopener noreferrer">
                <img src={`../../icons/icon-note-lg.svg`} alt="note"/>
              </a>
            </BtnNote>
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: 'soryusha_books',
                // id: "soryusha_books"
              }}
              options={{
                lang: "ja",
                width: "100%",
                height: "400",
                // theme: "dark",
              }}
            />
          </Aside>
        </ElseMain>
      </>
    )
  } else {
    container = (
      <>
        <Header />
        <ElseMain>
          <MainChild>
            {children}
          </MainChild>
          <Aside>
            <WidgetBooksCat />
            <WidgetNews />
            <BtnNote>
              <a href="https://note.com/soryusha/" target="_blank" rel="noopener noreferrer">
                <img src={`../../icons/icon-note-lg.svg`} alt="note"/>
              </a>
            </BtnNote>
            <Timeline
              dataSource={{
                sourceType: 'profile',
                screenName: 'soryusha_books',
                // id: "soryusha_books"
              }}
              options={{
                lang: "ja",
                width: "100%",
                height: "400",
                // theme: "dark",
              }}
            />
          </Aside>
        </ElseMain>
      </>
    )
  }

  return (
    <>
      {/* {loading} */}
      <Menu width={250} />
      <Container>{container}</Container>
      <Footer/>
      <Scroll showBelow={250} />
      {/* <Script/> */}
    </>
  )
}

export default Layout
