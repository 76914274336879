import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { NewsThumb, NewsThumbImg, NewsThumbTtl, NewsDate, NewsThumbCat } from "../style/common/common"

const NewsLoop = ({ post }) => {

  let thumbnail
  const thumbImgs = post.frontmatter.thumb?.map((thumbImg, index) => <GatsbyImage image={getImage(thumbImg)} alt={post.frontmatter.title} key={index}/>)

  if (thumbImgs) {
    thumbnail = thumbImgs[0]
  } else {
    thumbnail = <></>
  }

  return (

    <NewsThumb>
      <Link to={`/news${post.fields.slug}`}>
        <NewsThumbImg>
          {thumbnail}
        </NewsThumbImg>
        <NewsThumbTtl>{post.frontmatter.title}</NewsThumbTtl>
        <NewsDate>{post.frontmatter.date}</NewsDate>
        <NewsThumbCat>{post.frontmatter.category}</NewsThumbCat>
      </Link>
    </NewsThumb>
  )
}

export default NewsLoop
