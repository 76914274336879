// components/scroll.js
import React, { useState, useEffect } from 'react'
import { StyledScroll } from "../style/common/common"
// import tsubu from '../images/icon-tsubu.svg'

const Scroll = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <StyledScroll>
      {show && (
        <button onClick={handleClick} id="scrollBtn" aria-label="to top">
          <img src={`../../icons/icon-tsubu.svg`} alt="素粒社"/>
        </button>
      )}
    </StyledScroll>
  )
}
export default Scroll
