import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"
import SNSGroup from "./snsGroup"
import { StyledMenu, MobileMenuLogo } from "../style/common/common"
// import logo from '../../logo--hrz.svg'
// import logoW from '../../logo--hrz--fff.svg'

export default props => {
  return (
    <StyledMenu>
      <MobileMenuLogo>
        <div><Link to="/"><img src={`../../icons/logo--hrz--fff.svg`} alt="素粒社"/></Link></div>
      </MobileMenuLogo>
      <Menu {...props}>
        <Link to="/" className="bm-item-logo"><img src={`../../icons/logo--hrz.svg`} alt="素粒社"/></Link>
        <Link to="/" className="mobile__menu__item">トップ</Link>
        <Link to="/books/" className="mobile__menu__item">刊行書籍</Link>
        <Link to="/news/" className="mobile__menu__item">お知らせ</Link>
        <a href="https://soryusha.stores.jp/" target="_blank" rel="noopener noreferrer" className="mobile__menu__item">ウェブショップ</a>
        <Link to="/howtoorder/" className="mobile__menu__item">お取引について</Link>
        <Link to="/contact/" className="mobile__menu__item">お問い合わせ</Link>
        <Link to="/about/" className="mobile__menu__item">会社案内</Link>
        <SNSGroup />
      </Menu>
    </StyledMenu>
  );
};
