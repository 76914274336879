import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
// import { node } from "prop-types"
// import ArticleItem from './ArticleItem'
// import styled from '@emotion/styled'

import NewsLoop from "./newsLoop"
import { WidgetWrapper, WidgetTitle, WidgetNewsBody } from "../style/common/common"

const WidgetNews = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {frontmatter: {posttype: {eq: "news"}}}
        sort: {order: DESC, fields: frontmatter___date}
        limit: 5
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "YYYY.MM.DD")
              title
              category
            }
          }
        }
      }
    }
  `)

  const NewsRecentPosts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map((edge, index) => <NewsLoop post={edge.node} key={index}/>)

  return (
    <WidgetWrapper>
      <WidgetTitle>お知らせ</WidgetTitle>
      <WidgetNewsBody>
        {NewsRecentPosts}
      </WidgetNewsBody>
    </WidgetWrapper>
  )
}

export default WidgetNews
