import { Link } from "gatsby"
import React from "react"
import Navigation from "./navigation"
import SNSGroupFtr from "./snsGroupFtr"
import { StyledFooter, FooterWrapper, FooterLogo, FooterProfile, FooterLeft, FooterRight, FooterBottom } from "../style/common/common"

const Footer = () => (
  <StyledFooter>
    <FooterWrapper>
      <FooterLeft>
        <FooterLogo>
          <Link to="/">
            <img src={`../../icons/logo--hrz.svg`} alt="素粒社"/>
          </Link>
        </FooterLogo>
        <FooterProfile>
          <dt>所在地</dt>
          <dd>〒184-0002<br />東京都小金井市梶野町1-2-36 KO-TO R-04</dd>
          <dt>TEL</dt>
          <dd>0422-77-4020</dd>
          <dt>FAX</dt>
          <dd>042-633-0979</dd>
        </FooterProfile>
      </FooterLeft>
      <FooterRight>
        <Navigation/>
        <SNSGroupFtr/>
      </FooterRight>
    </FooterWrapper>
    <FooterBottom>
      <small>&copy; soryusha</small>
    </FooterBottom>
  </StyledFooter>
)
export default Footer
