import React from 'react'
import { StyledSNSGroup, BtnSNS } from '../style/common/common'
// import twitter from '../../icon-twitter.svg'
// import note from '../../icon-note-sm.svg'

const SNSGroupFtr = () => {
  return (
    <StyledSNSGroup>
      <BtnSNS>
        <a href="https://twitter.com/soryusha_books" target="_blank" rel="noopener noreferrer">
        <span><img src={`../../icons/icon-twitter.svg`} alt="twitter"/></span>Twitter
      </a>
      </BtnSNS>
      <BtnSNS>
        <a href="https://note.com/soryusha/" target="_blank" rel="noopener noreferrer">
          <span><img src={`../../icons/icon-note-sm.svg`} alt="note"/></span>note
        </a>
      </BtnSNS>
    </StyledSNSGroup>
  )
}

export default SNSGroupFtr
