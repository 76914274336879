import React from 'react'
import { Link } from 'gatsby'
import Navigation from "./navigation"
import SNSGroup from './snsGroup'
import { StyledHeader, HdrLogo, HdrNav } from '../style/common/common'
// import logo from '../../logo--vrt.svg'

const Header = () => {
  return (
    <StyledHeader>
      <HdrLogo>
        <Link to="/">
          <img src={`../../icons/logo--vrt.svg`} alt="素粒社"/>
        </Link>
      </HdrLogo>
      <HdrNav>
        <Navigation />
        <SNSGroup/>
      </HdrNav>
    </StyledHeader>
  )
}
export default Header;
