import * as React from 'react';
import _ from "lodash"
import kebabCase from 'lodash/kebabCase'
import { Link, graphql, useStaticQuery } from 'gatsby';
import { WidgetWrapper, WidgetCatBody, WidgetTitle } from '../style/common/common';

const WidgetBooksCat = () => {
  const query = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: {frontmatter: {posttype: {eq: "books"}}}) {
        group(field: frontmatter___category) {
          totalCount
          fieldValue
        }
      }
    }
  `);
  const group = query.allMarkdownRemark.group.sort(
    (a, b) => b.totalCount - a.totalCount
  );

  return (
    <WidgetWrapper>
      <WidgetTitle>カテゴリ</WidgetTitle>
      <WidgetCatBody>
        <ul>
          <li><Link to={`/books/`}>すべて</Link></li>
          {group.map(cat => (
            <li key={cat.fieldValue}>
              <Link to={`/books/${kebabCase(cat.fieldValue)}/`}>
                {cat.fieldValue} ({cat.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </WidgetCatBody>
    </WidgetWrapper>
  )
};

export default WidgetBooksCat;
