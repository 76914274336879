import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import BooksLoop from "./booksLoop"
import { WidgetWrapper, WidgetTitle, WidgetBooksBody } from "../style/common/common"

const WidgetBooks = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {frontmatter: {posttype: {eq: "books"}}}
        sort: {order: DESC, fields: frontmatter___date}
        limit: 5
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "")
              title
              author
              pubDate
              category
              thumb {
                childImageSharp {
                  gatsbyImageData(width: 70)
                }
              }
            }
          }
        }
      }
    }
  `)

  const BookPosts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge, index) => <BooksLoop post={edge.node} key={index}/>)

  return (
    <WidgetWrapper>
      <WidgetTitle>刊行書籍</WidgetTitle>
      <WidgetBooksBody>
        {BookPosts}
      </WidgetBooksBody>
    </WidgetWrapper>
  )
}

export default WidgetBooks
