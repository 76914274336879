import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BookThumb, BookThumbTxt, BookThumbImg, BookThumbTtl, BookThumbMeta, BookThumbAut, BookThumbCat, BadgeNew, BadgeSoon } from '../style/common/common'

const BooksLoop = ({ post }) => {
  const thumbImgs = post.frontmatter.thumb?.map((thumbImg, index) => <GatsbyImage key={index} image={getImage(thumbImg)} alt={post.frontmatter.title}/>)
  let badge

  if (post.frontmatter.badge === "coming") {
    badge = <BadgeSoon><span>COMMING SOON</span></BadgeSoon>
  } else if (post.frontmatter.badge === "new") {
    badge = <BadgeNew><span>NEW!</span></BadgeNew>
  } else {
    badge = <></>
  }

  return (
    <BookThumb>
      <Link to={`/books${post.fields.slug}`}>
        <BookThumbImg>
          {thumbImgs[0]}
        </BookThumbImg>
        <BookThumbTxt>
          <BookThumbTtl>{post.frontmatter.title}</BookThumbTtl>
          <BookThumbMeta>
            <BookThumbAut>{post.frontmatter.author}</BookThumbAut>
            <BookThumbCat>{post.frontmatter.category}</BookThumbCat>
          </BookThumbMeta>
        </BookThumbTxt>
        {badge}
      </Link>
    </BookThumb>
  )
}

export default BooksLoop
