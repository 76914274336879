import React from 'react'
import { Link } from 'gatsby'
import { StyledNav } from '../style/common/common'

const Navigation = () => {
  // const NavMenuItem = ["トップ", "刊行書籍", "お知らせ", "ウェブショップ", "お取引について", "お問い合わせ", "会社案内"];

  // const NavMenuLiTag = NavMenuItem.map((item) => {

  //   let page_link = ""

  //   if (item === "トップ") {
  //     page_link = "/"
  //   } else if (item === "刊行書籍") {
  //     page_link = "/books/"
  //   } else if (item === "お知らせ") {
  //     page_link = "/news/"
  //   } else if (item === "ウェブショップ") {
  //     page_link = "https://soryusha.stores.jp/";
  //     page_rel = "noreferrer noopener"
  //     page_target="_blank"
  //   } else if (item === "お取引について") {
  //     page_link = "/howtoorder/"
  //   } else if (item === "お問い合わせ") {
  //     page_link = "/contact/"
  //   } else {
  //     page_link = "/about/"
  //   }

  //   return (
  //     <li key={page_link}>
  //       <Link to={page_link} className="page-link">{item}</Link>
  //     </li>
  //   )
  // });

  return (
    <StyledNav>
      <ul>
        <li key="/">
          <Link to="/" className="page-link">トップ</Link>
        </li>
        <li key="/books/">
          <Link to="/books/" className="page-link">刊行書籍</Link>
        </li>
        <li key="/news/">
          <Link to="/news/" className="page-link">お知らせ</Link>
        </li>
        <li key="/stores/">
          <a href="https://soryusha.stores.jp/" rel = "noreferrer noopener" target="_blank" className="page-link">ウェブショップ</a>
        </li>
        <li key="/howtoorder/">
          <Link to="/howtoorder/" className="page-link">お取引について</Link>
        </li>
        <li key="/contact/">
          <Link to="/contact/" className="page-link">お問い合わせ</Link>
        </li>
        <li key="/about/">
          <Link to="/about/" className="page-link">会社案内</Link>
        </li>
      </ul>
    </StyledNav>
  )
}

export default Navigation;
